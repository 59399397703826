import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosSettings, IoMdInformationCircleOutline } from "react-icons/io";
import { CiCamera } from "react-icons/ci";
import { Tooltip } from 'react-tooltip';
import Cropper from 'react-easy-crop'
import { useHistory } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
export default function Photo() {
    const [fileSelected, setFileSelected] = useState(false);
    const [uncroppedImage, setUncroppedImage] = useState("");
    const [filename, setFileName] = useState("");
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);
    const [croppedState, setCroppedState] = useState(false);
    const [croppedImage, setCroppedImage] = useState(false);

    const [crop, setCrop] = useState({
        x: 0, y: 0
    })

    const [zoom, setZoom] = useState(1);
    const [selectedFile, setSelectedFile] = useState("");
    const [cropperState, setCropperState] = useState(false); // main 
    const [newImage, setNewImage] = useState(null);
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', 'anonymous')
            image.src = url
        })

    const getCroppedImg = async (imageSrc, crop) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        /* setting canvas width & height allows us to 
        resize from the original image resolution */
        canvas.width = 250
        canvas.height = 250

        ctx.drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            canvas.width,
            canvas.height
        )

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    const onCropComplete = async (_, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            imagePreview,
            croppedAreaPixels
        )

        const croppedURL = URL.createObjectURL(croppedImage);
        const croppedFile = new File([croppedImage], filename, {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
        });


        const formData = new FormData();
        console.log(croppedFile, "cropped")
        formData.append('file', croppedFile);
        setSelectedFile(formData);
        setNewImage(croppedURL);
    }

    // const [fetchedURL, setFetchedURL] = useState("");
    // const [fileId, setFileId] = useState(null);
    // const [finalPage, setFinalPage] = useState(false)

    console.log(selectedFile, "file")


    const [loading, setIsLoading] = useState(false);

    const history = useHistory();



    const callAPi = async () => {
        setIsLoading(true); // Set loading to true before making the API call

        const formData = new FormData();
        formData.append('file', uncroppedImage);

        try {
            const response = await fetch('https://app.composemind.com/api/first_glance/upload', {
                method: 'POST',
                body: selectedFile ? selectedFile : formData
            });

            if (response.ok) {
                setCroppedState(false);
                const data = await response.json();
                console.log(data, "data")
                if (data.file_id) {
                    const { file_id } = data;
                    const { innerWidth, innerHeight } = window;
                    const queryParams = `file_id=${file_id}&width=${innerWidth}&height=${innerHeight}`;
                    window.location.href = `/first_glance_final?${queryParams}`;
                } else {
                    console.error("No file ID returned from the API");
                }
            } else {
                console.error("Error while fetching data from the API");
            }
        } catch (error) {
            console.error("Error occurred while fetching data:", error);
        } finally {
            setIsLoading(false); // Set loading to false after receiving the response
        }
    };





    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = file.size / 1024 / 1024; // Convert to MB

            if (fileSize > 5) {
                alert("File size exceeds 5MB. Please choose a smaller file.");
                // Optionally, you can clear the file input
                fileInputRef.current.value = "";
                return;
            }

            const fileType = file.type;
            if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "image/webp") {
                alert("Invalid file type. Please choose a JPG or PNG image.");
                // Optionally, you can clear the file input
                fileInputRef.current.value = "";
                return;
            }

            // Handle the file as needed (e.g., upload or display preview)
            setFileSelected(true);
            setFileName(file.name);
            setUncroppedImage(file);
            const previewURL = URL.createObjectURL(file);
            setImagePreview(previewURL);

            setCroppedState(true);
            setCropperState(true);
        }
    };

    console.log(uncroppedImage, "uncropped")

    return (
        <div>
            {croppedState === false && !loading ? (
                <div className="bg-gray-200 w-screen lg:h-screen max-md:h-full flex items-center justify-center">
                    <div className="flex flex-col lg:w-[32%] lg:h-fit w-screen h-screen bg-white items-center pb-4 rounded-lg shadow-lg shadow-gray-400">
                        <div className="flex flex-row justify-between items-center w-full p-2">
                            <IoIosArrowBack className="text-3xl hover:cursor-pointer" />
                            <p className="text-xl font-sans">Upload Your Photo</p>
                            <IoIosSettings className="text-3xl hover:cursor-pointer" />
                        </div>

                        <div className="bg-gray-400 lg:w-72 lg:h-60 mt-20 max-sm:w-[70%] max-sm:h-[30%] w-[50%] h-[40%]" style={{ backgroundImage: `url(${imagePreview})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="lg:w-64 text-center mt-8 lg:text-base text-xl w-screen lg:p-0 p-10">Upload a photo and discover English names tailored for you!</p>

                        <div className="flex flex-row justify-center items-center gap-12 mt-10">
                            <label className="bg-[#FB9E2C] rounded-full w-12 h-12 flex justify-center items-center" htmlFor="fileInput">
                                <CiCamera className="text-white lg:text-3xl text-4xl hover:cursor-pointer" />
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                accept=".jpg, .jpeg, .png , .webp"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <IoMdInformationCircleOutline className="lg:text-3xl text-4xl hover:cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Supported: JPG, JPEG, PNG. Max size: 5MB" />
                            <Tooltip id="my-tooltip" place="bottom" />
                        </div>

                        {fileSelected &&
                            <p className="lg:mt-4 mt-10 text-blue-500 font-bold text-center">Selected File: <span className="text-black">{filename}</span></p>
                        }

                        <button
                            className="p-2 lg:w-44 w-[70%] rounded-md bg-[#FB9E2C] text-white font-semibold mt-14"
                            onClick={() => setCroppedState(true)}
                            disabled={!imagePreview}
                        >
                            Continue
                        </button>

                    </div>
                </div>
            ) : croppedState === true && !loading ? (
                <div className="bg-gray-200 w-screen h-screen flex items-center justify-center">
                    <div className="flex flex-col lg:w-[32%] w-screen lg:h-fit max-md:h-full h-screen bg-white items-center pb-4 rounded-lg shadow-lg shadow-gray-400">
                        <div className="flex flex-row justify-between items-center w-full p-2">
                            <IoIosArrowBack className="text-3xl hover:cursor-pointer" onClick={() => setCroppedState(false)} />
                            <p className="text-xl font-sans">Upload Your Photo</p>
                            <IoIosSettings className="text-3xl hover:cursor-pointer" />
                        </div>

                        <div onClick={() => setCropperState(true)} className="bg-gray-400 lg:w-72 lg:h-60 mt-20 rounded-md max-sm:w-[70%] max-sm:h-[30%] w-[50%] h-[40%]">
                            {croppedImage === true ? (
                                <img
                                    src={newImage}
                                    alt="Description of the image"
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    className="rounded-md"
                                />
                            ) : (
                                <img
                                    src={imagePreview}
                                    alt="Description of the image"
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            )}
                        </div>

                        {cropperState === true && (
                            <div onClick={() => {
                                setCropperState(false);
                                setCroppedImage(true);
                            }}>
                                <Cropper
                                    image={imagePreview}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                        )}

                        {/* <div className="flex flex-row justify-center items-center gap-12 mt-10">
                            <label className="bg-[#FB9E2C] rounded-full w-12 h-12 flex justify-center items-center" htmlFor="fileInput">
                                <CiCamera className="text-white lg:text-3xl text-4xl hover:cursor-pointer" />
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                accept=".jpg, .jpeg, .png"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <IoMdInformationCircleOutline className="lg:text-3xl text-4xl hover:cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Supported: JPG, JPEG, PNG. Max size: 5MB" />
                            <Tooltip id="my-tooltip" place="bottom" />
                        </div> */}

                        {fileSelected &&
                            <p className="lg:mt-4 mt-10 text-blue-500 font-bold text-center">Selected File: <span className="text-black">{filename}</span></p>
                        }

                        <button className="p-2 lg:w-44 w-[70%] rounded-md bg-[#FB9E2C] text-white font-semibold mt-14" onClick={() => callAPi()}>Continue</button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center w-screen h-screen">
                    <Hourglass
                        visible={true}
                        height={300}
                        width={150}
                        ariaLabel="hourglass-loading"
                        color={['#306cce', '#72a1ed']}
                    />
                </div>
            )
            }
        </div >
    );
}
