import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Photo from './Photo/photo';
import Glance from './FinalPage/glance';
import GlanceFinal from './FinalPage/glanceFinal';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Photo />
        </Route>
        <Route path="/first_glance_final" exact>
          <GlanceFinal />
        </Route>
        <Route path="/first_glance" exact>
          <Glance />
        </Route>
        <Route path="/first_glance/:file_id/:width/:height">
          <Glance />
        </Route>

      </Switch>
    </Router>
  );
}

export default App;