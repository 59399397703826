import React from "react";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosSettings, IoMdInformationCircleOutline } from "react-icons/io";
import { CiCamera } from "react-icons/ci";
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { FaDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
export default function Glance() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const file_id = queryParams.get('file_id');

    const [fetchedURL, setFetchedURL] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    console.log(file_id, "file id");

    const [apiResponse, setApiResponse] = useState(null);

    console.log(apiResponse, "response")

    const getDesc = async () => {
        try {
            console.log(file_id, "inside")
            const response = await fetch(`https://app.composemind.com/api/first_glance/analyze_image?file_id=${file_id}`, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();
                setApiResponse(data);
                setFetchedURL(data.file_url);
            } else {
                console.error("Error fetching API");
            }
        } catch (error) {
            console.error("Error fetching API:", error);
        } finally {
            setIsLoading(false); // Set loading to false when request completes
        }
    };

    useEffect(() => {
        if (file_id) {
            getDesc();
        };

    }, []);

    console.log(apiResponse, "response")
    return (
        <div className="bg-gray-200 w-screen h-screen flex items-center justify-center">
            {isLoading ? (
                <div className="flex justify-center items-center w-screen h-screen">
                    <Hourglass visible={true}
                        height="300"
                        width="150"
                        ariaLabel="hourglass-loading"
                        colors={['#306cce', '#72a1ed']} />
                </div>
            ) : (
                <div className="flex flex-col lg:w-[32%] lg:h-screen max-md:h-full w-screen h-screen bg-[#FDE3A4] items-center rounded-lg shadow-lg shadow-gray-400">
                    <div className="flex flex-row justify-center items-center w-full p-2">
                        {/* <IoIosArrowBack className="text-3xl hover:cursor-pointer" /> */}
                        <p className="text-2xl font-sans font-bold mt-2">第一眼英文名</p>
                        {/* <IoIosSettings className="text-3xl hover:cursor-pointer" /> */}
                    </div>


                    {/*<div className="lg:w-52 lg:h-48 mt-4 w-[70%] h-[40%]" style={{ backgroundImage: `url(${fetchedURL})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-testid={'uploaded_image'}></div>*/}
                    <img src={fetchedURL} data-testid={'uploaded_image'} alt="image" className="rounded-lg w-[50%] mt-1 mb-1"/>

                    <p className="lg:w-full text-center lg:mt-8 text-base w-screen lg:p- pl-2 pr-2">
                        {apiResponse?.compliment}
                    </p>

                    {apiResponse != null && <div className="bg-[#FBC35C] w-[90%] h-fit rounded-2xl lg:mt-3 ">
                        <div className="p-4 space-y-3 leading-tight lg:leading-none">
                            <div className="space-y-1">
                                <button className="bg-[#4CBFA8] pt-2 pb-2 pl-4 pr-4 rounded-full w-fit font-bold">{apiResponse?.name_list[0].name}</button>
                                <p>{apiResponse?.name_list[0]?.description}</p>
                            </div>
                            <div className="space-y-1">
                                <button className="bg-[#FB9E2D] pt-2 pb-2 pl-4 pr-4 rounded-full font-bold">{apiResponse?.name_list[1].name}</button>
                                <p>{apiResponse?.name_list[1]?.description}</p>
                            </div>
                            <div className="space-y-1">
                                <button className="bg-[#5D86CA] pt-2 pb-2 pl-4 pr-4 rounded-full font-bold">{apiResponse?.name_list[2].name}</button>
                                <p>{apiResponse?.name_list[2]?.description}</p>
                            </div>
                        </div>
                    </div>}


                    <div className="mt-2 flex flex-row items-center justify-between gap-x-6">
                        <div className="max-w-60 text-center font-semibold">Start your study abroad with LingoLeap.ai,easing the the TOEFL/IELTS hurdle!</div>
                        <div className="bg-[#FCC22E] rounded-full w-32 h-32 flex items-center justify-center">
                            <img src="/qr.jpeg" alt="qr" className="w-20 h-20" />
                        </div>
                    </div>
                </div>)}
        </div>
    )
}