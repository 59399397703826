import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Hourglass, Oval } from "react-loader-spinner";

export default function GlanceFinal() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const file_id = queryParams.get('file_id');

    const resultPageUrl = `https://app.composemind.com/first_glance?file_id=${file_id}`
    const screenshotUrl = new URL("https://app.composemind.com/api/first_glance/screenshot");
    const { innerWidth, innerHeight } = window;

    const params = new URLSearchParams();
    params.append('url', resultPageUrl);
    params.append('width', innerWidth);
    params.append('height', innerHeight);
    screenshotUrl.search = params.toString();

    console.log(screenshotUrl.toString(), "url")

    const [imageLoaded, setImageLoaded] = useState(false);
    // API call to start loading before the timeout
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(screenshotUrl);
    //             if (response.ok) {
    //                 console.log("done")
    //             } else {
    //                 console.error("Error fetching screenshot API");
    //             }
    //         } catch (error) {
    //             console.error("Error fetching screenshot API:", error);
    //         }
    //     };

    //     fetchData(); // Call the function immediately
    // }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

    // // Set a timeout to hide the loader after 12 seconds
    // // useEffect(() => {
    // //     const timeout = setTimeout(() => {
    // //         setIsLoading(false);
    // //     }, 12000); // 12 seconds
    // //     return () => clearTimeout(timeout); // Cleanup function to clear timeout
    // // }, []);

    // // useEffect(() => {
    // //     console.log(imageLoaded)
    // // }, [imageLoaded]
    // // )

    return (
        <div className="bg-gray-200 w-screen lg:h-full max-md:h-screen h-screen flex items-center justify-center">

            {!imageLoaded &&
                <div className="flex justify-center items-center w-screen h-screen">
                    <div
                        className="inline-block h-28 w-28 text-yellow-700 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                        >
                    </div>
                </div>
            }

            <div className={`flex flex-col lg:w-[32%] lg:h-fit max-md:h-full w-screen h-fit bg-[#FDE3A4] items-center rounded-lg shadow-lg shadow-gray-400 ${!imageLoaded ? 'hidden' : 'block'}`}>
                <img src={screenshotUrl.toString()} alt="image" className="mt-4 rounded-lg min-h-fit" style={{ backgroundSize: 'cover', backgroundPosition: 'center' }} onLoad={() => setImageLoaded(true)} />

                <div className="flex flex-col w-[90%] gap-y-4 mt-2 ">
                    <div>
                        <span className="flex flex-row justify-center items-center"><FaDownload className="text-2xl" /><p className="text-center font-bold"> 长按保存图片，让朋友帮你投票吧!</p></span>
                    </div>
                    <div className="flex justify-between lg:gap-0 gap-3">
                        <button
                            className="bg-[#E7E7E7] rounded-lg p-1 w-44 font-semibold"
                            onClick={() => window.location.href = 'https://app.composemind.com/'}
                        >
                            再玩一次
                        </button>
                        <button
                            className="bg-[#FA7E5A] rounded-lg p-1 w-60 font-semibold"
                            onClick={() => window.location.href = 'https://lingoleap.ai/'}
                        >
                            免费试用 LingoLeap.ai
                        </button>
                    </div>
                </div>

                <div className="bg-[#2A4842] text-white font-bold text-center tracking-wider mt-2 lg:p-0 p-1">
                    <p>开启留学之旅，让LingoLeap.ai 陪你轻松迈过托福/雅思的门槛！</p>
                </div>
            </div>

        </div>
    );
}
